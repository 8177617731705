import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LanguageState } from '../../store/language.state';
import { TranslateModule } from '@ngx-translate/core';
import { HowToUseCardComponent } from '../../pages/how-to-use/components/how-to-use-card/how-to-use-card.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-legacy-user-onboarding-cards',
  templateUrl: './legacy-user-onboarding-cards.component.html',
  styleUrls: ['./legacy-user-onboarding-cards.component.scss'],
  standalone: true,
  imports: [TranslateModule, HowToUseCardComponent],
})
export class LegacyUserOnboardingCardsComponent {
  constructor(private languageState: LanguageState) {}

  getImage(step: 2 | 3 | 4): string {
    return `assets/imgs/legacy-users-onboarding/step-${step}-${this.languageState.language$.value.selected}.${step === 2 ? 'png' : 'svg'}`;
  }
}
