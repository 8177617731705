<div class="legacy-user-onboarding-cards-container">
  <app-how-to-use-card [number]="'PWA_HowToUseBooksmart_part1_number' | translate" [title]="'PWA_HowToUseBooksmart_part1_text_guest' | translate">
    <div slot="imgs" class="part1">
      <img src="../../../assets/imgs/legacy-users-onboarding/step-1-1.svg" alt="" />
    </div>
  </app-how-to-use-card>
  <app-how-to-use-card [number]="'PWA_HowToUseBooksmart_part2_number' | translate" [title]="'PWA_HowToUseBooksmart_part2_text' | translate">
    <div slot="imgs" class="part2">
      <img [src]="getImage(2)" alt="" />
    </div>
  </app-how-to-use-card>
  <app-how-to-use-card [number]="'PWA_HowToUseBooksmart_part3_number' | translate" [title]="'PWA_HowToUseBooksmart_part3_text' | translate">
    <div slot="imgs" class="part3">
      <img [src]="getImage(3)" alt="" />
    </div>
  </app-how-to-use-card>

  <app-how-to-use-card [number]="'PWA_HowToUseBooksmart_part4_number' | translate" [title]="'PWA_HowToUseBooksmart_part4_text' | translate">
    <div slot="imgs" class="part4">
      <img [src]="getImage(4)" alt="" />
    </div>
  </app-how-to-use-card>
</div>
